let env = '${ENV_NAME}';
let apiUrl = '${API_URL}';
let baseUrl = '${BASE_URL}';
let production = '${IS_PROD}';
let sociuuhubApiUrl = '${HUB_URL}';
let sentryDSN = '${SENTRY_FE_DSN}';
let sentryAuth = '${SENTRY_AUTH_TOKEN}';

export let environment = {
  env: env,
  apiUrl: apiUrl,
  baseUrl: baseUrl,
  production: production,
  sociuuhubApiUrl: sociuuhubApiUrl,
  sentryDSN: sentryDSN,
  sentryAuth: sentryAuth,
};
